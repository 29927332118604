import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  Column3,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Heading1,
} from "./SlideElements";

const InfoSection = ({
  lightBg,
  id,
  lightText,
  lightText1,
  imgStart,
  topLine,
  headline,
  headline1,
  darkText,
  description,
}) => {
  const [counterOn, setCounterOne] = useState(false);

  return (
    <>
      <ScrollTrigger
        onEnter={() => setCounterOne(true)}
        onExit={() => setCounterOne(false)}
      >
        <InfoContainer lightBg={lightBg} id={id}>
          <InfoWrapper>
            <InfoRow>
              <Column1>
                <TextWrapper>
                  <TopLine></TopLine>

                  <Heading lightText={lightText}>SCHOOLS REACHED</Heading>
                  <Heading1 lightText1={lightText1}>{headline1}</Heading1>
                  <Subtitle darkText={darkText}>
                    +
                    {counterOn && (
                      <CountUp start={0} end={10} duration={2} delay={0} />
                    )}
                  </Subtitle>
                  <BtnWrap></BtnWrap>
                </TextWrapper>
              </Column1>
              <Column2>
                <TextWrapper>
                  <Heading lightText={lightText}>DISTRICTS COVERED</Heading>
                  <Heading1 lightText1={lightText1}> </Heading1>
                  <Subtitle darkText={darkText}>
                    +{" "}
                    {counterOn && (
                      <CountUp start={0} end={42} duration={5} delay={0} />
                    )}
                  </Subtitle>
                  <BtnWrap></BtnWrap>
                </TextWrapper>
              </Column2>
              <Column3>
                <TextWrapper>
                  <Heading lightText={lightText}>STUDENTS CONNECTED</Heading>
                  <Heading1 lightText1={lightText1}></Heading1>
                  <Subtitle darkText={darkText}>
                    +{" "}
                    {counterOn && (
                      <CountUp start={0} end={500} duration={5} delay={0} />
                    )}
                  </Subtitle>
                  <BtnWrap></BtnWrap>
                </TextWrapper>
              </Column3>
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
      </ScrollTrigger>
    </>
  );
};

export default InfoSection;
